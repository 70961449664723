import Vue from "vue";
import Vuex from "vuex";
import * as user from "@/store/modules/user.js";
import * as notification from "@/store/modules/notification.js";
import * as pubhub from "@/store/modules/pubhub.js";
import * as uploadServer from "@/store/modules/uploadServer.js";
import * as uploadDetails from "@/store/modules/uploadDetails.js";
import * as ui from "@/store/modules/ui.js";
import * as dashboard from "@/store/modules/dashboard.js";
import * as etl_1 from "@/store/modules/etl_1.js";
import * as etl_2 from "@/store/modules/etl_2.js";
import * as db_1 from "@/store/modules/db_1.js";
import * as retail_1 from "@/store/modules/retail_1.js";
import * as fsdb_1 from "@/store/modules/fsdb_1.js";
import * as spdb_1 from "@/store/modules/spdb_1.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    notification,
    pubhub,
    uploadServer,
    uploadDetails,
    ui,
    dashboard,
    etl_1,
    etl_2,
    db_1,
    retail_1,
    fsdb_1,
    spdb_1,
  },
});
